import { makeStyles } from '@material-ui/core';

import { createMediaQuery } from '../../models/dimensions.model';
import { MAX_WIDGET_WIDTH } from '../../utils/sizes.utils';

export const useWidgetFooterStyles = makeStyles(
	({
		palette: {
			colorScheme,
			sizes,
			borderRadius,
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		root: {
			width: '100vw',
			maxWidth: MAX_WIDGET_WIDTH,
			alignItems: 'center',
			position: 'absolute',
			opacity: 0,
			bottom: '-2%',
			transition: noAnimationTransition ? 'initial' : 'all 0.4s ease',
			zIndex: 4,
			padding: `0 ${sizes.size_1}% ${sizes.size_1}%`,
			...createMediaQuery('mobile_landscape', { paddingBottom: 12 }),
			...colorScheme.customStyle.footer,
		},
		backgroundOverlay: {
			'&:before': {
				content: '""',
				height: 800,
				background: colorScheme.colorVariables.colorBgOverlay,
				borderTopLeftRadius: borderRadius.widget,
				borderTopRightRadius: borderRadius.widget,
				position: 'absolute',
				top: 22,
				left: 0,
				right: 0,
				zIndex: 2,
			},
		},
		openedWidget: {
			bottom: 0,
			opacity: 1,
		},
		noHeight: {
			paddingBottom: 0,
		},
		smallHeight: {
			paddingBottom: '4%',
			background: colorScheme.colorVariables.colorBgOverlay,
		},
		inputWrapper: {
			minHeight: sizes.size_6,
			opacity: 1,
		},
	}),
);

export const useInputCSSTransitionStyles = makeStyles(
	({
		palette: {
			transitionConfig: { noAnimationTransition },
			sizes,
		},
	}) => ({
		enterActive: {
			height: sizes.size_6,
			opacity: 1,
			transition: noAnimationTransition ? 'initial' : 'all 600ms',
			transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
		},
		exitActive: {
			height: 0,
			opacity: 0,
			transition: noAnimationTransition ? 'initial' : 'all 600ms',
			transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
		},
	}),
);

export const useProgressbarCSSTransitionStyles = makeStyles(
	({
		palette: {
			sizes,
			transitionConfig: { noAnimationTransition },
		},
	}) => ({
		enter: {
			opacity: 0,
			height: 0,
			paddingTop: 0,
		},
		enterActive: {
			opacity: 1,
			transition: noAnimationTransition ? 'initial' : 'all 600ms',
			transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
			height: 28,
			paddingTop: sizes.size_1,
		},
		exit: {
			opacity: 1,
			height: 28,
		},
		exitActive: {
			height: 0,
			paddingTop: 0,
			opacity: 0,
			transition: noAnimationTransition ? 'initial' : 'all 400ms',
			transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
		},
	}),
);
